
import { defineComponent, ref, watch } from "vue";
import ApiService from "@/core/services/ApiService";
import { ElNotification } from "element-plus";
import { hideModal } from "@/core/helpers/dom";

interface Filter {
  user_id: any;
  amount: number;
  description: string;
}

export default defineComponent({
  name: "create-financial-log",
  props: {
    widgetClasses: String,
    user: {
      type: Object,
      default: undefined,
    },
  },
  emits: ["refreshList"],
  setup(props, { emit }) {
    const modalRef = ref();
    const forUser = ref();
    const data = ref<Filter>({
      user_id: 0,
      amount: 0,
      description: "",
    });

    const reset = () => {
      data.value = {
        user_id: 0,
        amount: 0,
        description: "",
      };
    };
    const submit = (type) => {
      let fData = { ...data.value };
      if (type === "decrease") fData.amount *= -1;
      ApiService.post("admin/user/financial/change", fData)
        .then(({ data }) => {
          reset();
          hideModal(modalRef.value);
          emit("refreshList");
        })
        .catch(({ response }) => {
          ElNotification({
            title: "خطا",
            message: "خطا در تغییر موجودی کیف پول کاربر",
            type: "error",
          });
        });
    };

    const toEnglishDigits = (nums) => {
      // convert persian digits [۰۱۲۳۴۵۶۷۸۹]
      var e = "۰".charCodeAt(0);
      nums = nums.replace(/[۰-۹]/g, function (t) {
        return t.charCodeAt(0) - e;
      });

      // convert arabic indic digits [٠١٢٣٤٥٦٧٨٩]
      e = "٠".charCodeAt(0);
      nums = nums.replace(/[٠-٩]/g, function (t) {
        return t.charCodeAt(0) - e;
      });

      return nums;
      // recipients.value = nums;
    };


    watch(
      () => props.user,
      (user) => {
        if (!user) {
          forUser.value = undefined;
          return;
        }

        user = JSON.parse(JSON.stringify(user));
        forUser.value = user;
        data.value.user_id = forUser.value.id;
      }
    );

    return {
      data,
      modalRef,
      reset,
      forUser,
      submit,
      toEnglishDigits,
    };
  },
});
