import { defineStore } from "pinia";
import { ref } from "vue";
import ApiService from "@/core/services/ApiService";
import userInfo from "@/core/services/UserInfo";
import { useRoute } from "vue-router";
import { useI18n } from "vue-i18n";

export const Countries = defineStore("CountriesInfo", () => {
  const user = userInfo.getUser();
  const route = useRoute();
  const i18n = useI18n();

  const setDefaultLang = () => {
    if (
      route.name === "sign-in" &&
      defaultCountry.value &&
      defaultCountry.value.alpha2_code
    ) {
      if (defaultCountry.value.alpha2_code === "IR") setLang("fa");
      else if (defaultCountry.value.alpha2_code === "TR") setLang("tr");
      else setLang("en");
    }
  };

  const setLang = (lang) => {
    if (localStorage.getItem("lang") !== lang) {
      localStorage.setItem("lang", lang);
      i18n.locale.value = lang;
    }
  };

  // state
  const countriesList = ref();
  const defaultCountry = ref();

  // getters

  // actions
  const getCountries = () => {
    ApiService.get("/country?sort=phone_code")
      .then(({ data }) => {
        countriesList.value = data.data.countries;

        const defaultCountryFilter =
          user && user.country !== null && user.country.alpha2_code
            ? user.country.alpha2_code
            : data.data.current_location.country_code;

        defaultCountry.value = countriesList.value.find(
          (item) => item.alpha2_code === defaultCountryFilter
        );

        setDefaultLang();
      })
      .catch(() => {
        getCountries();
      });
  };

  return {
    countriesList,
    defaultCountry,
    getCountries,
  };
});
