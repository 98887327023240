
import { defineComponent, onMounted, ref, watch } from "vue";
import { hideModal } from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import ApiService from "@/core/services/ApiService";
import { ElNotification } from "element-plus";
import { Mutations } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";

interface NewAddressData {
  first_name: string;
  last_name: string;
  mobile: string;
  email: string;
  type: string;
  status: string;
  gender: string;
  ref_id: string;
  campaign_id: string;
  password: string;
  password_confirmation: string;
}

export default defineComponent({
  name: "crud-users-list-modal",
  components: {},
  props: {
    open: {
      type: Number,
      default: 0,
    },
    user: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props, { emit }) {
    const store = useStore();
    const formRef = ref<null | HTMLFormElement>(null);
    const newTargetModalRef = ref<null | HTMLElement>(null);
    const loading = ref<boolean>(false);
    const editUser = ref();
    const errorsBag = ref({});
    const targetData = ref<NewAddressData>({
      first_name: "",
      last_name: "",
      mobile: "",
      email: "",
      type: "",
      status: "active",
      gender: "man",
      ref_id: "",
      campaign_id: "",
      password: "",
      password_confirmation: "",
    });

    const rules = ref({
      first_name: [
        {
          required: true,
          message: "لطفا نام کاربر را وارد کنید",
          trigger: "blur",
        },
      ],
      last_name: [
        {
          required: true,
          message: "لطفا نام خانوادگی کاربر را وارد کنید",
          trigger: "blur",
        },
      ],
      mobile: [
        {
          required: true,
          message: "لطفا شماره موبایل کاربر را وارد کنید",
          trigger: "blur",
        },
      ],
      email: [
        {
          required: false,
          message: "لطفا ایمیل کاربر را وارد کنید",
          trigger: "blur",
        },
      ],
      type: [
        {
          required: true,
          message: "لطفا نوع کاربر را انتخاب کنید",
          trigger: "change",
        },
      ],
    });

    const submit = () => {
      if (!formRef.value) {
        return;
      }
      formRef.value.validate((valid) => {
        if (valid) {
          loading.value = true;

          ApiService.post(`admin/user/${editUser.value.id}`, targetData.value)
            .then(() => {
              loading.value = false;
              emit("refreshUsersList");
              hideModal(newTargetModalRef.value);
            })
            .catch((e) => {
              if (e.response!.status === 400) {
                errorsBag.value = e.response.data.data;
              }
              store.commit(Mutations.SET_ERROR, e.response.data);
            });
        } else {
          Swal.fire({
            text: "Sorry, looks like there are some errors detected, please try again.",
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Ok, got it!",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
          return false;
        }
      });


    };
      const toEnglishDigits = (nums) => {
      // convert persian digits [۰۱۲۳۴۵۶۷۸۹]
      var e = "۰".charCodeAt(0);
      nums = nums.replace(/[۰-۹]/g, function (t) {
        return t.charCodeAt(0) - e;
      });

      // convert arabic indic digits [٠١٢٣٤٥٦٧٨٩]
      e = "٠".charCodeAt(0);
      nums = nums.replace(/[٠-٩]/g, function (t) {
        return t.charCodeAt(0) - e;
      });

      return nums;
      // recipients.value = nums;
    };

    const campaignsList = ref();
    const fetchCampaigns = () => {
      ApiService.get("admin/campaigns/all")
        .then(({ data }) => {
          campaignsList.value = data.data.campaigns;
        })
        .catch(({ response }) => {
          store.commit(Mutations.SET_ERROR, response.data);
        });
    };

    watch(
      () => props.user,
      (user) => {
        fetchCampaigns();
        user = JSON.parse(JSON.stringify(user));

        editUser.value = user;
        targetData.value = {
          first_name: user.first_name,
          last_name: user.last_name,
          mobile: user.mobile,
          email: user.email,
          type: user.type,
          status: user.status,
          gender: user.gender,
          ref_id: user.representative?.unique_id,
          campaign_id: user.representative?.campaign_id,
          password: user.password,
          password_confirmation: user.password_confirmation,
        };
      }
    );

    return {
      targetData,
      submit,
      loading,
      formRef,
      rules,
      newTargetModalRef,
      errorsBag,
      campaignsList,
      toEnglishDigits,
    };
  },
});
